'use client';

import React from "react"
import { RegionCityList } from "./RegionCityList"

type EmpireSidebarInfoProps = {
  region: Region
}

export function RegionSidebarInfo({ region }: EmpireSidebarInfoProps) {
  const description = region.description as string
  const trivia = region.trivia as string

  return (
    <>
      {!description ? "" : (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {!trivia ? "" : (
        <>
          <h3>Trivia</h3>
          <div dangerouslySetInnerHTML={{ __html: trivia }} />
        </>
      )}
      {(region.cities && region.cities.length > 0) ? (
        <>
          <h3>Städte & Dörfer</h3>
          <RegionCityList region={region} />
        </>
      ) : ""}

    </>
  )
}

