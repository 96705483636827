import sidebarStyles from "./Sidebar.module.scss"
import Image from "next/image"
import React from "react"
import {Carousel} from "@material-tailwind/react"

type TitleImageCarouselProps = {
    images: string[],
    title?: string
}

export function TitleImageCarousel({images, title}: TitleImageCarouselProps) {
    let props = {
        prevArrow: images.length > 1 ? undefined : () => {
        },
        nextArrow: images.length > 1 ? undefined : () => {
        },
    }

    return (
        <div className={sidebarStyles["sidebar-img-top"]}>
            <Carousel
                placeholder={undefined}
                loop
                {...props}
                autoplay
                navigation={({setActiveIndex, activeIndex, length}) => {
                    if (length > 1) {
                        return (
                            <div className="absolute bottom-8 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                {new Array(length).fill("").map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                            activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                        }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )
                    }
                }}
            >
                {images.map((image, index) => (
                        <Image
                            key={image}
                            className="h-full w-full object-cover"
                            width={500}
                            height={350}
                            loading={index === 1 ? "eager" : "lazy"}
                            src={image}
                            alt={title || ""}/>
                    )
                )}
            </Carousel>
        </div>
    )
}