import React, {useEffect} from "react"
import Layout from "../components/Layout"
import classNames from "classnames"
import {Sidebar} from "@/components/sidebar/Sidebar"
import styles from "../pages/map/MapView.module.scss"
import {SplashScreen} from "@/components/splashscreen/SplashScreen"
import dynamic from "next/dynamic"
import Head from "next/head"
import {AlluenaSidebar} from "@/components/sidebar/AlluenaSidebar"

const Searchbar = dynamic(
    () => import("../components/search/Searchbar"),
    {ssr: false}
)

type MapLayoutProps = {
    sidebar?: React.ReactNode,
    title?: string,
    activeMarker?: City | POI | Region
    markers?: Array<City | POI | Region>,
    map: React.ReactNode,
    isLoading: boolean,
    isSidebarLoading: boolean
}

export function MapLayout({
                              sidebar,
                              map: mapChild,
                              isLoading,
                              isSidebarLoading,
                              activeMarker,
                              title,
                              markers
                          }: MapLayoutProps) {
    const ogDescription = activeMarker?.description ?? "Eine Karte der Welt von Gerald van Olzius."
    const [showSplashScreen, setShowSplashScreen] = React.useState(isLoading)

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setShowSplashScreen(false)
            }, 1000)
        }
    }, [isLoading])


    return (
        <Layout title={title ? `Karte :: ${title}` : ""}>
            <Head>
                <meta property="og:description" content={ogDescription}/>
                <meta property="og:image" content={activeMarker?.images[0]}/>
                <meta property="twitter:card" content="summary"/>
            </Head>
            <div className={classNames("d-flex", "w-full")}>
                <Searchbar term={activeMarker?.name} markers={markers}/>

                {sidebar ? (
                    <Sidebar marker={activeMarker} isSidebarLoading={isSidebarLoading}>
                        {sidebar}
                    </Sidebar>
                ) : ""}


                <AlluenaSidebar/>

                <section className={styles.content}>
                    {showSplashScreen ? <SplashScreen loading={isLoading} label="Karte wird gezeichnet..."/> : ""}
                    {mapChild}
                </section>
            </div>
        </Layout>
    )
}
